/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-10-29",
    versionChannel: "nightly",
    buildDate: "2023-10-29T00:21:39.687Z",
    commitHash: "cc49aaee58be9786e62c241c67daed33c0b535e3",
};
